import { request, noTimeOutReq } from "@/utils/request.js";

// 展示还未归档的考试列表(弃用)
// export function getExamNotArchiveListApi(pageIndex, pageSize) {
//   return request({
//     url: `/exam/archives/list/yet/${pageIndex}/${pageSize}`,
//     method: "post",
//   });
// }

// 展示已归档的考试列表(弃用)
// export function getExamArchiveListApi(pageIndex, pageSize) {
//   return request({
//     url: `/exam/archives/list/have/${pageIndex}/${pageSize}`,
//     method: "post",
//   });
// }

// 根据考试id归档所有信息
export function archiveExamApi(examId) {
  return request({
    url: `/exam/archives/all/${examId}`,
    method: "post",
  });
}

// 展示归档列表
export function getExamArchiveListApi(pageIndex, pageSize, data) {
  return request({
    url: `/exam/archives/view/list/${pageIndex}/${pageSize}`,
    method: "post",
    data
  });
}

// 数据完整性检查
export function dataCheckApi(examId, repeatCheck) {
  return request({
    url: `/exam/archives/view/data/check/${examId}/${repeatCheck}`,
    method: "post"
  });
}

// 考试数据展示
export function getExamDataApi(examArchivesExamId) {
  return request({
    url: `/exam/archives/view/get/exam/${examArchivesExamId}`,
    method: "post"
  });
}

// 查看考生考试数据
export function getExamineeDetailApi(examArchivesExamineeId) {
  return request({
    url: `/exam/archives/view/get/examinee/data/${examArchivesExamineeId}`,
    method: "post"
  });
}

// 考生数据展示
export function getExamineeListApi(examArchivesExamId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/archives/view/get/examinee/${examArchivesExamId}/${pageIndex}/${pageSize}`,
    method: "post",
    data
  });
}

// 教师数据展示
export function getTeacherListApi(examArchivesExamId, pageIndex, pageSize, data) {
  return request({
    url: `/exam/archives/view/get/teacher/${examArchivesExamId}/${pageIndex}/${pageSize}`,
    method: "post",
    data
  });
}

// 查看考生基本信息
export function getStuBaseInfoApi(examArchivesExamineeId) {
  return request({
    url: `/exam/archives/view/get/examinee/info/${examArchivesExamineeId}`,
    method: "post",
  });
}

// 清除考试归档
export function delExamArchiveInfoApi(examId) {
  return request({
    url: `/exam/archives/clear/exam/${examId}`,
    method: "post",
  });
}
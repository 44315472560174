<template>
    <div id="app">
      <!-- 视频组件 -->
      <dualVideoCom
        ref="dualVideoCom"
        :mainStreamUrl="mainStreamUrl"
        :subStreamUrl="subStreamUrl"
        :examOperationTest="pageData"
        :openAI="openAI"
        @renderPointFrame="renderPointFrame"
        :showFace="showFace"
        @changeShowFace="
          (val) => {
            showFace = val;
          }
        "
        :showExamineeInfo="showExamineeInfo"
        :examineeInfo="examineeInfo"
      ></dualVideoCom>
  
      <!-- 表格 -->
      <el-row
        v-if="isMarked"
        :gutter="5"
        id="scoreDetailsTable"
        v-loading="loading"
      >
        <el-col :span="12">
          <el-table
            stripe
            border
            ref="markingVideoTable"
            :data="leftOperationQuestionList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              show-overflow-tooltip
              :width="56"
            ></el-table-column>
            <el-table-column label="评分项" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  scope.row.operationQuestionName
                    ? scope.row.operationQuestionName
                    : scope.row.operationItemName
                }}
                <el-tooltip
                  v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                  effect="dark"
                  :content="scope.row.markSuggest.remark"
                  placement="bottom"
                >
                  <i
                    class="el-icon-warning-outline"
                    style="float: right; line-height: 23px; font-size: 18px"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <template v-if="showExamineeInfo">
              <el-table-column
                prop="operationItemScore"
                label="分值"
                show-overflow-tooltip
                :width="61"
              ></el-table-column>
              <el-table-column
                prop="score"
                label="得分"
                show-overflow-tooltip
                :width="61"
              ></el-table-column>
            </template>
            <template v-else>
              <el-table-column
                prop="score"
                label="分值"
                show-overflow-tooltip
                :width="61"
              ></el-table-column>
            </template>
  
            <el-table-column
              v-for="(item, index) in pageData.markTeacherRecords"
              :key="index"
              :label="
                item.scoreType == 2
                  ? item.teacherName + '(仲裁)'
                  : item.scoreType == 4
                  ? item.teacherName + '(异议)'
                  : item.teacherName
              "
              :width="60"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(from, index) in item.operationAchievements"
                  :key="index"
                >
                  <span
                    v-if="
                      from.operationQuestionId == scope.row.operationQuestionId
                    "
                    >{{ from.score }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="aiScore"
              label="AI评分"
              :width="80"
              align="center"
              v-if="openAI"
            >
              <template slot-scope="scope">{{
                scope.row.aiSupport ? scope.row.aiScore : 0
              }}</template>
            </el-table-column>
            <el-table-column
              align="center"
              v-if="openAI"
              label="评分依据"
              show-overflow-tooltip
              :width="120"
              v-once
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                      .length > 0
                  "
                  class="pointFrameImg"
                  style="height: 54px"
                  @click="
                    jumpPointFrameListVideo(
                      renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    )
                  "
                >
                  <img
                    :src="
                      renderPointFrameImg(
                        renderPointFrame(
                          scope.row.aiResultOperationQuestionItemDTO
                        )
                      )
                    "
                  />
                </div>
                <div v-else style="height: 54px"></div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
  
        <el-col :span="12">
          <!-- ,'backgroundColor':'#dfe6ec' -->
          <el-table
            stripe
            border
            ref="markingVideoTable"
            :data="rightOperationQuestionList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              show-overflow-tooltip
              :width="56"
            ></el-table-column>
            <el-table-column label="评分项" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  scope.row.operationQuestionName
                    ? scope.row.operationQuestionName
                    : scope.row.operationItemName
                }}
                <el-tooltip
                  v-if="scope.row.markSuggest && scope.row.markSuggest.remark"
                  effect="dark"
                  :content="scope.row.markSuggest.remark"
                  placement="bottom"
                >
                  <i
                    class="el-icon-warning-outline"
                    style="float: right; line-height: 23px; font-size: 18px"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <template v-if="showExamineeInfo">
              <el-table-column
                prop="operationItemScore"
                label="分值"
                show-overflow-tooltip
                :width="61"
              ></el-table-column>
              <el-table-column
                prop="score"
                label="得分"
                show-overflow-tooltip
                :width="61"
              ></el-table-column>
            </template>
            <template v-else>
              <el-table-column
                prop="score"
                label="分值"
                show-overflow-tooltip
                :width="61"
              ></el-table-column>
            </template>
  
            <el-table-column
              v-for="(item, index) in pageData.markTeacherRecords"
              :key="index"
              :label="
                item.scoreType == 2
                  ? item.teacherName + '(仲裁)'
                  : item.scoreType == 4
                  ? item.teacherName + '(异议)'
                  : item.teacherName
              "
              :width="60"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-for="(from, index) in item.operationAchievements"
                  :key="index"
                >
                  <span
                    v-if="
                      from.operationQuestionId == scope.row.operationQuestionId
                    "
                    >{{ from.score }}</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="aiScore"
              label="AI评分"
              :width="80"
              v-if="openAI"
            >
              <template slot-scope="scope">{{
                scope.row.aiSupport ? scope.row.aiScore : 0
              }}</template>
            </el-table-column>
            <el-table-column
              align="center"
              v-if="openAI"
              label="评分依据"
              show-overflow-tooltip
              :width="120"
              v-once
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                      .length > 0
                  "
                  class="pointFrameImg"
                  style="height: 54px"
                  @click="
                    jumpPointFrameListVideo(
                      renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                    )
                  "
                >
                  <img
                    :src="
                      renderPointFrameImg(
                        renderPointFrame(
                          scope.row.aiResultOperationQuestionItemDTO
                        )
                      )
                    "
                  />
                </div>
                <div v-else style="height: 54px"></div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
  
      <div id="exit_btn">
        <span
          v-if="showExamineeInfo && isMarked"
          style="font-size: 18px; margin-right: 20px"
        >
          考生得分：
          <span style="font-size: 28px; font-weight: 500; color: #09c0c8">{{
            totalScore
          }}</span>
          分
        </span>
        <el-button v-throttle type="primary" size="medium" @click="exit"
          >退出</el-button
        >
      </div>
    </div>
  </template>
  <script>
  import dualVideoCom from "@/components/dualVideoCom.vue";
  import { isEmpty, durationToTimeSting } from "@/utils/util";
  import { getAchievementApi } from "@/api/score/scoreDetails.js";
  import { getExamineeAiMarkingApi } from "@/api/marking/ai.js";
  import { getExamineeDetailApi } from "@/api/archive/archiveManagement.js";
  import { setStore } from '@/utils/token.js'
  export default {
    name: "scoreDetails",
    components: {
      dualVideoCom,
    },
    data() {
      return {
        loading: false,
        //播放器相关
        mainStreamUrl: "",
        subStreamUrl: "",
        //路由参数
        urlParam: {
          achievementSummaryId: 0,
          examId: 0,
          subject: 0,
        },
        pageData: {},
        aiMarkResult: {},
        frameList: [],
        //表格数据
        operationQuestionList: [],
        leftOperationQuestionList: [],
        rightOperationQuestionList: [],
        openAI: false,
        firstPointShow: false,
  
        showFace: false,
        showExamineeInfo: false,
        totalScore: 0,
        examineeInfo: {},
        isMarked: 1, // 是否已阅卷
      };
    },
  
    beforeDestroy() {
      // this.destroyPlayer()
    },
    mounted() {
      this.renderUrlParam();
      // this.initPlayer(this.mainStreamUrl, this.subStreamUrl)
    },
    methods: {
      renderUrlParam() {
        this.urlParam.achievementSummaryId = 0;
        this.urlParam.achievementSummaryId =
          this.$route.query.achievementSummaryId;
        this.urlParam.examId = 0;
        this.urlParam.examId = this.$route.query.examId;
        this.urlParam.subject = 0;
        this.urlParam.subject = this.$route.query.subject;
        // console.log(this.$route.query.examArchivesExamineeId,"this.$route.query.examineeId")
        if (!!this.$route.query.examArchivesExamineeId) {
          this.showExamineeInfo = true;
          this.examineeInfo = JSON.parse(this.$route.query.examineeInfo);
          this.totalScore = this.examineeInfo.score;
          this.isMarked = +this.$route.query.isMarked;
        }
        this.renderPageData();
      },
      getExamineeAiMarking() {
        getExamineeAiMarkingApi(this.urlParam.examId, this.pageData.examineeId)
          .then((res) => {
            if (res.success) {
              this.aiMarkResult = {};
              this.aiMarkResult = res.data;
              this.$refs.dualVideoCom.aiMarkResult = this.aiMarkResult;
              // 获取AI初评的分数
              if (
                this.aiMarkResult.aiResultOperationQuestionItemDTOList &&
                this.aiMarkResult.aiResultOperationQuestionItemDTOList.length > 0
              ) {
                for (
                  let i = 0;
                  i < this.pageData.operationQuestions.length;
                  i++
                ) {
                  let optionItem = this.pageData.operationQuestions[i];
                  let item =
                    this.aiMarkResult.aiResultOperationQuestionItemDTOList[i];
                  optionItem.aiSupport = true;
                  this.$set(
                    optionItem,
                    "aiOperationItemName",
                    item.aiOperationItemName
                  );
                  this.$set(optionItem, "inputScore", item.aiScore);
                  this.$set(optionItem, "aiScore", item.aiScore);
                  optionItem.aiResultOperationQuestionItemDTO = item;
                  let currentFrameList = this.renderPointFrame(item);
                  if (currentFrameList.length > 0) {
                    this.frameList = this.frameList.concat(currentFrameList);
                    this.$refs.dualVideoCom.frameList = this.frameList;
                  }
                }
                this.frameList.sort(this.frameListSort);
                this.$refs.dualVideoCom.frameList = this.frameList;
                let nextFrameStart = 0;
                for (
                  let i = this.pageData.operationQuestions.length - 1;
                  i >= 0;
                  i--
                ) {
                  let operationQuestion = this.pageData.operationQuestions[i];
                  let currentFrameList = this.renderPointFrame(
                    operationQuestion.aiResultOperationQuestionItemDTO
                  );
                  if (currentFrameList.length > 0) {
                    currentFrameList.sort(this.frameListSort);
                    let firstFrame = currentFrameList[0];
                    if (firstFrame.frameTime) {
                      this.$set(
                        operationQuestion,
                        "frameStartTime",
                        firstFrame.frameTime
                      );
                      this.$set(
                        operationQuestion,
                        "frameEndTime",
                        nextFrameStart
                      );
                      this.$set(operationQuestion, "frameFirst", firstFrame);
                      nextFrameStart = operationQuestion.frameStartTime;
                    }
                  }
                }
              }
            } else {
              if (res.code != "500") {
                this.msgWarning("未获取到考生的AI评分成绩");
              } else {
                this.msgError("获取成绩明细信息失败");
              }
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
      },
      renderPageData() {
        if (
          isEmpty(this.urlParam.examId) ||
          isEmpty(this.urlParam.achievementSummaryId)
        ) {
          return;
        }
        if (!this.showExamineeInfo) {
          this.getAchievement();
        } else {
          console.log(this.pageData.examineeId, "this.pageData.examineeId");
          this.getExaminieeDetail();
        }
      },
      //查看成绩明细
      getAchievement() {
        this.loading = true;
        getAchievementApi(
          this.urlParam.examId,
          this.urlParam.achievementSummaryId
        )
          .then((res) => {
            this.loading = false;
            if (res.success) {
              this.pageData = {};
              this.pageData = res.data;
              if (res.data.aiSupport) {
                this.openAI = true;
                // dataList = await this.getAiMark(this.examId, res.data.examineeId)
              }
              // 调组件
              this.$refs.dualVideoCom.getExamineeVideo(this.pageData.examineeId);
              //考试名称过长处理
              if (this.pageData.examName.length > 25) {
                this.pageData.aliasExamName =
                  this.pageData.examName.substring(0, 25) + "...";
              } else {
                this.pageData.aliasExamName = this.pageData.examName;
              }
              //流地址处理
              this.mainStreamUrl = this.$imageAddress(
                this.pageData.topCameraStream0
              );
              if (!isEmpty(this.pageData.frontCameraStream0)) {
                this.subStreamUrl = this.$imageAddress(
                  this.pageData.frontCameraStream0
                );
              } else if (!isEmpty(this.pageData.sideCameraStream0)) {
                this.subStreamUrl = this.$imageAddress(
                  this.pageData.sideCameraStream0
                );
              } else {
                this.subStreamUrl = this.$imageAddress(
                  this.pageData.frontCameraStream0
                );
              }
              this.$nextTick(() => {
                // this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
                this.$refs.dualVideoCom.initPlayer(
                  this.mainStreamUrl,
                  this.subStreamUrl
                );
                // // 调组件
                // this.$refs.dualVideoCom.startPlayer(
                //   this.mainStreamUrl,
                //   this.subStreamUrl
                // )
              });
              //table数据处理
              this.operationQuestionList = [];
              this.operationQuestionList = this.pageData.operationQuestions;
              this.leftOperationQuestionList = [];
              this.rightOperationQuestionList = [];
              let listSize = this.operationQuestionList.length;
              let splitPoint = Math.ceil(listSize / 2);
              for (let i = 0; i < listSize; i++) {
                let item = this.pageData.operationQuestions[i];
                item.aiSupport = false;
                item.aiScore = 0;
                item.aiResultOperationQuestionItemDTO = [];
              }
              this.operationQuestionList.forEach((item, index) => {
                item.index = index + 1;
                if (index < splitPoint) {
                  this.leftOperationQuestionList.push(item);
                } else {
                  this.rightOperationQuestionList.push(item);
                }
              });
              if (this.openAI) {
                this.getExamineeAiMarking();
              }
            } else {
              if (res.code != "500") {
                this.msgWarning(res.msg);
              } else {
                this.msgError("获取成绩明细信息失败");
              }
            }
          })
          .catch((e) => {
            this.loading = false;
          });
      },
      getExaminieeDetail() {
        this.loading = true;
  
        getExamineeDetailApi(this.examineeInfo.examArchivesExamineeId)
          .then((res) => {
            this.loading = false;
            if (res.success) {
              this.pageData = {};
              this.pageData = res.data;
              this.pageData.operationQuestions =
                this.pageData.examArchivesExamineeQuestionMarkRecordInfoList[0]?.examArchivesExamOperationAchievementDTOList;
              if (res.data.aiSupport) {
                this.openAI = true;
                // dataList = await this.getAiMark(this.examId, res.data.examineeId)
              }
              // 调组件
              this.$refs.dualVideoCom.getExamineeVideo(
                this.examineeInfo.examArchivesExamineeId
              );
              //考试名称过长处理
              if (this.pageData.examName.length > 25) {
                this.pageData.aliasExamName =
                  this.pageData.examName.substring(0, 25) + "...";
              } else {
                this.pageData.aliasExamName = this.pageData.examName;
              }
              //流地址处理
              this.mainStreamUrl = this.$imageAddress(
                this.pageData.topCameraStream0
              );
              if (!isEmpty(this.pageData.frontCameraStream0)) {
                this.subStreamUrl = this.$imageAddress(
                  this.pageData.frontCameraStream0
                );
              } else if (!isEmpty(this.pageData.sideCameraStream0)) {
                this.subStreamUrl = this.$imageAddress(
                  this.pageData.sideCameraStream0
                );
              } else {
                this.subStreamUrl = this.$imageAddress(
                  this.pageData.frontCameraStream0
                );
              }
  
              this.$nextTick(() => {
                // this.startPlayer(this.mainStreamUrl, this.subStreamUrl)
                console.log(this.mainStreamUrl);
                console.log(this.subStreamUrl);
                this.$refs.dualVideoCom.initPlayer(
                  this.mainStreamUrl,
                  this.subStreamUrl
                );
                // // 调组件
                // this.$refs.dualVideoCom.startPlayer(
                //   this.mainStreamUrl,
                //   this.subStreamUrl
                // )
              });
              //table数据处理
              if (!!this.pageData.operationQuestions) {
                this.operationQuestionList = [];
                this.operationQuestionList =
                  this.pageData.examArchivesExamineeQuestionMarkRecordInfoList[0].examArchivesExamOperationAchievementDTOList;
                this.leftOperationQuestionList = [];
                this.rightOperationQuestionList = [];
                let listSize = this.operationQuestionList.length;
                let splitPoint = Math.ceil(listSize / 2);
                for (let i = 0; i < listSize; i++) {
                  let item = this.pageData.operationQuestions[i];
                  item.aiSupport = false;
                  item.aiScore = 0;
                  item.aiResultOperationQuestionItemDTO = [];
                }
                this.operationQuestionList.forEach((item, index) => {
                  item.index = index + 1;
                  if (index < splitPoint) {
                    this.leftOperationQuestionList.push(item);
                  } else {
                    this.rightOperationQuestionList.push(item);
                  }
                });
              }
  
              if (this.openAI) {
                this.getExamineeAiMarking();
              }
            } else {
              if (res.code != "500") {
                this.msgWarning(res.msg);
              } else {
                this.msgError("获取成绩明细信息失败");
              }
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      },
      jumpPointFrameListVideo(aiResultOperationQuestionItemDTOList) {
        if (aiResultOperationQuestionItemDTOList.length > 0) {
          this.$refs.dualVideoCom.currentDuration = Math.floor(
            aiResultOperationQuestionItemDTOList[0].frameTime / 1000
          );
          this.$refs.dualVideoCom.customSwitchProgress();
        }
      },
      renderPointFrame(aiResultOperationQuestionItemDTO) {
        let list = aiResultOperationQuestionItemDTO.aiResultItemPointDTOList;
        if (list && list.length > 0) {
          for (let index = 0; index < list.length; index++) {
            let eventList = list[index]?.aiResultItemPointEventDTOList;
            let aiEventList =
              eventList?.[0]?.aiResultItemPointEventAiEventDTOList;
            let frameList =
              aiEventList?.[0]?.aiResultItemPointEventAiEventFrameDTOList;
            if (frameList && frameList.length > 0) {
              frameList.forEach((item) => {
                item.displayContent = list[0].aiPointContent;
              });
              return frameList;
            }
          }
        }
        return [];
      },
      renderPointFrameImg(aiResultItemPointEventAiEventFrameDTOList) {
        if (aiResultItemPointEventAiEventFrameDTOList.length > 0) {
          return this.$imageAddress(
            aiResultItemPointEventAiEventFrameDTOList[0].frameUrl
          );
        } else {
          return "";
        }
      },
      exit() {
        // if (isEmpty(this.urlParam.subject)) {
        //   this.$router.push({
        //     path: '/examManage/examDetailed',
        //     query: {
        //       examId: this.urlParam.examId,
        //     },
        //   })
        // } else {
        //   this.$router.push({
        //     path: '/examManage/examObjectionView',
        //     query: {
        //       examId: this.urlParam.examId,
        //       subject: this.urlParam.subject,
        //     },
        //   })
        // }
        /* this.globalMessageBox(` 确定退出吗？`, '提示', '确定', '取消', 'warning').then(() => {
    
          }).catch(() => {
    
          }); */
          setStore('archiveExamineeExamedStatue', "third")
        this.$router.back();
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  #app {
    padding: 15px;
  }
  
  #scoreDetailsTable {
    margin-top: 5px;
  }
  
  /* #scoreDetailsTable ::v-deep .el-table .el-table__cell {
      padding: 6px 0;
    } */
  
  #exit_btn {
    margin-top: 20px;
    text-align: center;
  }
  
  .pointFrameImg {
    width: 100%;
    height: 100%;
    border: 1px solid #cbd8f5;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  </style>
  
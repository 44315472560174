var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('dualVideoCom',{ref:"dualVideoCom",attrs:{"mainStreamUrl":_vm.mainStreamUrl,"subStreamUrl":_vm.subStreamUrl,"examOperationTest":_vm.pageData,"openAI":_vm.openAI,"showFace":_vm.showFace,"showExamineeInfo":_vm.showExamineeInfo,"examineeInfo":_vm.examineeInfo},on:{"renderPointFrame":_vm.renderPointFrame,"changeShowFace":(val) => {
        _vm.showFace = val;
      }}}),(_vm.isMarked)?_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"gutter":5,"id":"scoreDetailsTable"}},[_c('el-col',{attrs:{"span":12}},[_c('el-table',{ref:"markingVideoTable",staticStyle:{"width":"100%"},attrs:{"stripe":"","border":"","data":_vm.leftOperationQuestionList,"tooltip-effect":"dark","header-cell-style":{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"序号","show-overflow-tooltip":"","width":56}}),_c('el-table-column',{attrs:{"label":"评分项","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.operationQuestionName ? scope.row.operationQuestionName : scope.row.operationItemName)+" "),(scope.row.markSuggest && scope.row.markSuggest.remark)?_c('el-tooltip',{attrs:{"effect":"dark","content":scope.row.markSuggest.remark,"placement":"bottom"}},[_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"float":"right","line-height":"23px","font-size":"18px"}})]):_vm._e()]}}],null,false,3412341854)}),(_vm.showExamineeInfo)?[_c('el-table-column',{attrs:{"prop":"operationItemScore","label":"分值","show-overflow-tooltip":"","width":61}}),_c('el-table-column',{attrs:{"prop":"score","label":"得分","show-overflow-tooltip":"","width":61}})]:[_c('el-table-column',{attrs:{"prop":"score","label":"分值","show-overflow-tooltip":"","width":61}})],_vm._l((_vm.pageData.markTeacherRecords),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":item.scoreType == 2
              ? item.teacherName + '(仲裁)'
              : item.scoreType == 4
              ? item.teacherName + '(异议)'
              : item.teacherName,"width":60,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((item.operationAchievements),function(from,index){return _c('div',{key:index},[(
                  from.operationQuestionId == scope.row.operationQuestionId
                )?_c('span',[_vm._v(_vm._s(from.score))]):_vm._e()])})}}],null,true)})}),(_vm.openAI)?_c('el-table-column',{attrs:{"prop":"aiScore","label":"AI评分","width":80,"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.aiSupport ? scope.row.aiScore : 0))]}}],null,false,3404270543)}):_vm._e(),(_vm.openAI)?_vm._m(0):_vm._e()],2)],1),_c('el-col',{attrs:{"span":12}},[_c('el-table',{ref:"markingVideoTable",staticStyle:{"width":"100%"},attrs:{"stripe":"","border":"","data":_vm.rightOperationQuestionList,"tooltip-effect":"dark","header-cell-style":{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"index","label":"序号","show-overflow-tooltip":"","width":56}}),_c('el-table-column',{attrs:{"label":"评分项","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.operationQuestionName ? scope.row.operationQuestionName : scope.row.operationItemName)+" "),(scope.row.markSuggest && scope.row.markSuggest.remark)?_c('el-tooltip',{attrs:{"effect":"dark","content":scope.row.markSuggest.remark,"placement":"bottom"}},[_c('i',{staticClass:"el-icon-warning-outline",staticStyle:{"float":"right","line-height":"23px","font-size":"18px"}})]):_vm._e()]}}],null,false,3412341854)}),(_vm.showExamineeInfo)?[_c('el-table-column',{attrs:{"prop":"operationItemScore","label":"分值","show-overflow-tooltip":"","width":61}}),_c('el-table-column',{attrs:{"prop":"score","label":"得分","show-overflow-tooltip":"","width":61}})]:[_c('el-table-column',{attrs:{"prop":"score","label":"分值","show-overflow-tooltip":"","width":61}})],_vm._l((_vm.pageData.markTeacherRecords),function(item,index){return _c('el-table-column',{key:index,attrs:{"label":item.scoreType == 2
              ? item.teacherName + '(仲裁)'
              : item.scoreType == 4
              ? item.teacherName + '(异议)'
              : item.teacherName,"width":60,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((item.operationAchievements),function(from,index){return _c('div',{key:index},[(
                  from.operationQuestionId == scope.row.operationQuestionId
                )?_c('span',[_vm._v(_vm._s(from.score))]):_vm._e()])})}}],null,true)})}),(_vm.openAI)?_c('el-table-column',{attrs:{"align":"center","prop":"aiScore","label":"AI评分","width":80},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.aiSupport ? scope.row.aiScore : 0))]}}],null,false,3404270543)}):_vm._e(),(_vm.openAI)?_vm._m(1):_vm._e()],2)],1)],1):_vm._e(),_c('div',{attrs:{"id":"exit_btn"}},[(_vm.showExamineeInfo && _vm.isMarked)?_c('span',{staticStyle:{"font-size":"18px","margin-right":"20px"}},[_vm._v(" 考生得分： "),_c('span',{staticStyle:{"font-size":"28px","font-weight":"500","color":"#09c0c8"}},[_vm._v(_vm._s(_vm.totalScore))]),_vm._v(" 分 ")]):_vm._e(),_c('el-button',{directives:[{name:"throttle",rawName:"v-throttle"}],attrs:{"type":"primary","size":"medium"},on:{"click":_vm.exit}},[_vm._v("退出")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('el-table-column',{attrs:{"align":"center","label":"评分依据","show-overflow-tooltip":"","width":120},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  .length > 0
              )?_c('div',{staticClass:"pointFrameImg",staticStyle:{"height":"54px"},on:{"click":function($event){_vm.jumpPointFrameListVideo(
                  _vm.renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                )}}},[_c('img',{attrs:{"src":_vm.renderPointFrameImg(
                    _vm.renderPointFrame(
                      scope.row.aiResultOperationQuestionItemDTO
                    )
                  )}})]):_c('div',{staticStyle:{"height":"54px"}})]}}],null,false,2432018968)})
},function (){var _vm=this,_c=_vm._self._c;return _c('el-table-column',{attrs:{"align":"center","label":"评分依据","show-overflow-tooltip":"","width":120},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                _vm.renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                  .length > 0
              )?_c('div',{staticClass:"pointFrameImg",staticStyle:{"height":"54px"},on:{"click":function($event){_vm.jumpPointFrameListVideo(
                  _vm.renderPointFrame(scope.row.aiResultOperationQuestionItemDTO)
                )}}},[_c('img',{attrs:{"src":_vm.renderPointFrameImg(
                    _vm.renderPointFrame(
                      scope.row.aiResultOperationQuestionItemDTO
                    )
                  )}})]):_c('div',{staticStyle:{"height":"54px"}})]}}],null,false,2432018968)})
}]

export { render, staticRenderFns }